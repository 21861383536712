<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">创客信息对比</span>
      </div>
    </div>
    <el-card>
      <div
        style="display: flex;
        justify-content: space-between;
        align-items: center;"
      >
        <div class="warning">
          <p>创客信息对比功能介绍：</p>
          <p>1.检查创客是否完成实名认证，以保障后续工作的顺利展开</p>
          <p>2.选择企业与平台签约的结算中心，检查合作创客银行卡是否超出限额</p>
          <p>
            3.导入名单
            <span style="color: #f56f6f" @click="downloadTemplate()">（点此下载名单模板）</span>后，界面会展示当前数据对比结果，也可以将结果导出至本地。
          </p>
        </div>
        <div class="operate-box">
          <el-select v-model="pay_platform" placeholder="请选择" style="margin-right: 10px">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.shortname"
              :value="item.pay_platform"
            ></el-option>
          </el-select>
          <el-button type="primary" @click="createAccounts">批量开户</el-button>
          <el-upload
            class="upload"
            :auto-upload="false"
            :multiple="false"
            :action="action"
            :on-change="handleChange"
            :file-list="fileList"
            :on-success="successFile"
            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          >
            <el-button type="primary">导入人员名单</el-button>
            <span>{{ fileName }}</span>
          </el-upload>
          <el-button type="primary" @click="exportExcel()">导出至本地</el-button>
          <i
            class="el-icon-refresh"
            style="color:#fecf1e;font-size:18px;font-weight:700;margin-left:10px"
            @click="refresh"
          ></i>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; padding: 20px 0"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="手机号码" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="身份证号" width="250">
          <template slot-scope="scope">
            <span>{{ scope.row.idCardNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开户行" width="250">
          <template slot-scope="scope">
            <span>{{ scope.row.bankName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="银行卡号" width="250">
          <template slot-scope="scope">
            <span>{{ scope.row.bankcode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="发放金额">
          <template slot-scope="scope">
            <span>{{ scope.row.amount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="日累计">
          <template slot-scope="scope">
            <span v-if="scope.row.dayLimitError" class="font-color-danger">
              {{
                scope.row.dayTotal
              }}
            </span>
            <span
              v-else
              class="font-color-success"
            >{{ scope.row.dayTotal }}/{{ scope.row.dayLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="月累计">
          <template slot-scope="scope">
            <span
              v-if="scope.row.monthLimitError"
              class="font-color-danger"
            >{{ scope.row.monthTotal }}/{{ scope.row.monthLimit }}</span>
            <span
              v-else
              class="font-color-success"
            >{{ scope.row.monthTotal }}/{{ scope.row.monthLimit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="查询结果" width="150">
          <template slot-scope="scope">
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.hasBankCardError"
              class="font-color-danger"
            >银行卡信息有误</p>

            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.certificationError"
              class="font-color-danger"
            >未认证</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.existError"
              class="font-color-danger"
            >查无此人</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.dayLimitError"
              class="font-color-danger"
            >超出日限额</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.monthLimitError"
              class="font-color-danger"
            >超出月限额</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.repeatError"
              class="font-color-danger"
            >人员重复</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.singleLimitError"
              class="font-color-danger"
            >超出单笔限额</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.taxLimitError"
              class="font-color-danger"
            >超出个税起征点</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="scope.row.hasSubAccountError"
              class="font-color-danger"
            >未开通子帐户</p>
            <p
              style="margin: 0px; padding: 0px"
              v-if="
                !scope.row.certificationError &&
                !scope.row.hasBankCardError &&
                !scope.row.existError &&
                !scope.row.dayLimitError &&
                !scope.row.monthLimitError &&
                !scope.row.repeatError &&
                !scope.row.singleLimitError &&
                !scope.row.hasSubAccountError &&
                !scope.row.taxLimitError
              "
              class="font-color-success"
            >认证成功</p>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import { api } from '/src/api/base';
import XLSX from 'xlsx';
import * as moment from 'moment';
// import baseUrl from "../../config/config";
export const userCompare = api()('user.compare.json');
export const accountCreate = api()('account.create.json');
import { parseJsonToTable } from '/src/lib/dev';
export default {
  data () {
    return {
      options: [],
      action: '',
      filePath: '',
      fileList: [],
      data: [],
      tableData: [],
      fileName: '',
      pay_platform: 'm1',
      selectList: []
    };
  },
  created () {
    this.action = window.location.origin + '/enterprise.upload.json';
    this.options = this.$route.params.$preload.platformList;

  },
  methods: {
    handleSelectionChange (val) {
      this.selectList = val
    },
    async createAccounts () {
      if (this.selectList.length === 0) {
        return this.$message.error('请先选择需开户的用户！')
      }
      try {
        const users = this.selectList.filter(it => !it.certificationError && it.hasSubAccountError).map(async it => {
          return await accountCreate({
            pay_platforms: this.pay_platform,
            code: it.code,
          })
        })
        await Promise.all(users)
        this.$message.success('人员开户成功！')
      } catch (error) {
        console.log(error);
      }
    },
    async compareUsers () {
      const res = await userCompare({
        data: this.data,
        pay_platform: this.pay_platform
      });
      this.dayLimit = res.dayLimit;
      this.monthLimit = res.monthLimit;
      this.singleLimit = res.singleLimit;
      this.tableData = res;
    },
    exportExcel () {
      const time = moment().format('YYMMDDHH');
      const data = this.tableData.map(it => {
        const _errors = [];
        if (it.hasBankCardError) {
          _errors.push('银行卡信息错误');
        } else if (it.certificationError) {
          _errors.push('未认证');
        } else if (it.dayLimitError) {
          _errors.push('日交易额超出限制');
        } else if (it.existError) {
          _errors.push('用户不存在');
        } else if (it.monthLimitError) {
          _errors.push('月交易额超出限制');
        } else if (it.singleLimitError) {
          _errors.push('超出单笔限额');
        } else if (it.taxLimitError) {
          _errors.push('超出个税起征点');
        } else if (it.repeatError) {
          _errors.push('人员重复');
        } else if (it.hasSubAccountError) {
          _errors.push('未开通子帐户');
        } else {
          _errors.push('认证成功');
        }
        return {
          ...it,
          certificationError: _errors.join(',')
        };
      });

      parseJsonToTable(
        `对比结果_${time}`,
        {
          姓名: 'name',
          手机号: 'phone',
          身份证号: 'idCardNumber',
          银行: 'bankName',
          银行卡号: 'bankcode',
          对比结果: 'certificationError',
          发放金额: 'amount'
        },
        data,
        true
      );
    },
    handleChange (param) {

      this.fileList = [];
      this.fileName = param.name;
      this.file2Xce(param).then(item => {
        if (item[0].sheet.length === 0) {
          return this.$message.error('传入表单无数据！');
        }
        const result = item[0].sheet.every(
          it => it['姓名'] === undefined || it['手机号'] === undefined
        );
        if (result) return this.$message.error('请完善表格信息');
        if (item && item.length > 0) {
          if (item[0] && item[0].sheet && item[0].sheet.length) {
            const dataArray = item[0].sheet.map(it => ({
              name: (it['姓名'] + '').replace(/\s+/g, ''),
              phone: (it['手机号'] + '').replace(/\s+/g, ''),
              amount: it['发放金额'] || 0
            }));
            this.data = dataArray || [];
            if (this.data.some(it => it === 'undefined')) {
              return this.$message.error('请完善上传名单信息');
            }

            this.compareUsers();
          }
        }
      });
    },
    file2Xce (file) {
      return new Promise(function (resolve) {

        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary'
          });
          const result = [];
          this.wb.SheetNames.forEach(sheetName => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName])
            });
          });
          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    successFile (res) {


      this.filePath = res.data;
    },
    downloadTemplate () {
      const _list = [];
      parseJsonToTable(
        '对比名单模板',
        {
          姓名: '',
          手机号: '',
          发放金额: ''
        },
        _list,
        true
      );
    },
    refresh () {
      this.compareUsers(this.data);
    }
  }
};
</script>
<style lang="scss">
.warning {
  padding: 8px 16px;
  // background-color: #ecf8ff;
  border-radius: 4px;
  // border-left: 5px solid #50bfff;
  color: #89a6fd;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
  p {
    margin: 14px 0;
  }
}
.operate-box {
  margin: 15px 0;
  display: flex;
  align-items: center;
}
.upload {
  display: flex;
  margin: 0 10px;
}
</style>
